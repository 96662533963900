import React from "react";
import styled from "styled-components";
import Slider from "react-slick";
import { mainPurple, darkPurple, screen } from "../variables";
import { GatsbyImage } from "gatsby-plugin-image";

const Wrapper = styled.div`
  .each-slide {
    .img {
      overflow: hidden;

      img {
        border-radius: 0 50px 0 0;
        @media ${screen.xsmall} {
          border-radius: 0 130px 0 0;
        }
        @media ${screen.medium} {
          border-radius: 0 180px 0 0;
        }
        @media ${screen.large} {
          border-radius: 0 200px 0 0;
        }
      }
    }

    .desc {
      position: relative;
      padding: 20px 0 0 34px;
      @media ${screen.xsmall} {
        padding: 20px 0 0 45px;
      }

      p {
        font-size: 1.05rem;
        margin: 0 0 21px 0;
        font-weight: 500;
        @media ${screen.xsmall} {
          font-size: 1.15rem;
        }
        @media ${screen.small} {
          font-size: 1.2rem;
        }
        @media ${screen.medium} {
          font-size: 1.25rem;
        }
        &:last-child {
          margin: 0;
        }
      }

      &:after {
        content: "“";
        color: ${mainPurple};
        font-size: 3rem;
        font-weight: 700;
        position: absolute;
        left: 0;
        top: 10px;
        @media ${screen.xsmall} {
          font-size: 4rem;
        }
      }
    }

    .logo {
      margin: 8px 0 0 34px;
      max-width: 170px;
      @media ${screen.xsmall} {
        margin: 9px 0 0 45px;
      }
    }
  }

  .slick-dots {
    bottom: -50px;

    li {
      margin: 0 2px;

      button {
        border-radius: 50%;
        border: 1px ${darkPurple} solid;
        width: 13px;
        height: 13px;

        &::before {
          opacity: 0;
        }
      }
    }

    .slick-active {
      button {
        background: ${darkPurple};
      }
    }
  }
`;

const settings = {
  dots: true,
  infinite: true,
  speed: 500,
  slidesToShow: 1,
  slidesToScroll: 1,
  arrows: false,
  fade: true,
  draggable: false,
  swipe: false,
  adaptiveHeight: true,
};

const Testimonials = ({ items, className }) => {
  return (
    <Wrapper className={className}>
      <Slider {...settings}>
        {items.map((item, i) => (
          <div className="each-slide" key={i}>
            {item.featured_image && (
              <figure className="img">
                <GatsbyImage
                  image={item.featured_image.thumbnails.cropped.gatsbyImageData}
                  alt={item.featured_image.alt || "Featured"}
                />
              </figure>
            )}

            <div
              className="desc"
              dangerouslySetInnerHTML={{
                __html: item.description.html,
              }}
            />

            {item.logo && item.logo.gatsbyImageData && (
              <div className="logo">
                <GatsbyImage
                  image={item.logo.gatsbyImageData}
                  alt={item.logo.alt || "Brand"}
                />
              </div>
            )}
          </div>
        ))}
      </Slider>
    </Wrapper>
  );
};

export default Testimonials;
